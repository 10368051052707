document.addEventListener('DOMContentLoaded', () => {

    const switcherTabs = () => {
        const navBtns = document.querySelectorAll('.js-tab-switcher-btn');
        const tabsContent = document.querySelectorAll('.js-tab-switcher-box');
        const line = document.querySelector('.js-tab-line');

        navBtns.forEach(el => {
            el.addEventListener('click', function (e) {
                const targetBtn = this.getAttribute('data-target');

                navBtns.forEach(btn1 => btn1.classList.remove('is-active'));
                tabsContent.forEach(tab1 => tab1.classList.remove('is-active'));

                tabsContent.forEach(tab => {
                    const tabMarker = tab.getAttribute('data-target');
                    if (tabMarker === targetBtn) tab.classList.add('is-active');
                });

                this.classList.add('is-active');
                line.style.top = this.offsetTop + 24 + 'px';

            });
        });
    }

    const customSelect = () => {
        /* Custom select */

        var x, i, j, l, ll, selElmnt, a, b, c;
        x = document.getElementsByClassName("js-custom-select");
        l = x.length;
        for (i = 0; i < l; i++) {
            selElmnt = x[i].getElementsByTagName("select")[0];
            ll = selElmnt.length;
            a = document.createElement("DIV");
            a.setAttribute("class", "c-custom-select__select-selected");
            let selectedOption;

            selElmnt.querySelectorAll('option').forEach(elInputs => {
                if (elInputs.classList.contains('is-active')) {
                    a.innerHTML = elInputs.innerHTML;
                    selectedOption = elInputs;
                }
            });

            // a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
            x[i].appendChild(a);

            b = document.createElement("DIV");
            b.setAttribute("class", "c-custom-select__select-items select-hide");
            for (j = 0; j < ll; j++) {
                c = document.createElement("DIV");
                c.innerHTML = selElmnt.options[j].innerHTML;
                var attr_link = selElmnt.options[j].getAttribute('data-target');
                if (attr_link) c.setAttribute('data-target', attr_link);
                if (selectedOption === selElmnt.options[j]) {
                    c.classList.add('same-as-selected');
                }
                c.addEventListener("click", function (e) {
                    var y, i, k, s, h, sl, yl;
                    s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                    sl = s.length;
                    h = this.parentNode.previousSibling;
                    for (i = 0; i < sl; i++) {
                        if (s.options[i].innerHTML == this.innerHTML) {
                            s.selectedIndex = i;
                            h.innerHTML = this.innerHTML;
                            y = this.parentNode.getElementsByClassName("same-as-selected");
                            yl = y.length;
                            for (k = 0; k < yl; k++) {
                                y[k].removeAttribute("class");
                            }
                            this.setAttribute("class", "same-as-selected");
                            break;
                        }
                    }

                    /* open choosen tabs */

                    let idAttr = e.target.getAttribute('data-target');
                    const tabsContent = document.querySelectorAll('.js-tab-switcher-box');
                    tabsContent.forEach(tab1 => tab1.classList.remove('is-active'));

                    tabsContent.forEach(tab => {
                        const tabMarker = tab.getAttribute('data-target');
                        if (tabMarker === idAttr) tab.classList.add('is-active');
                    });

                    h.click();
                });
                b.appendChild(c);
            }
            x[i].appendChild(b);
            a.addEventListener("click", function (e) {
                e.stopPropagation();
                closeAllSelect(this);
                this.nextSibling.classList.toggle("select-hide");
                this.classList.toggle("select-arrow-active");
            });
        }

        function closeAllSelect(elmnt) {
            var x, y, i, xl, yl, arrNo = [];
            x = document.getElementsByClassName("c-custom-select__select-items");
            y = document.getElementsByClassName("select-selected");
            xl = x.length;
            yl = y.length;
            for (i = 0; i < yl; i++) {
                if (elmnt == y[i]) {
                    arrNo.push(i)
                } else {
                    y[i].classList.remove("select-arrow-active");
                }
            }
            for (i = 0; i < xl; i++) {
                if (arrNo.indexOf(i)) {
                    x[i].classList.add("select-hide");
                }
            }
        }

        document.addEventListener("click", closeAllSelect);

    }


    customSelect();
    switcherTabs();

});